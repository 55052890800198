<template>
  <v-dialog v-model="visible" max-width="650">
    <v-card v-if="event">
        <v-card-title>
            <span class="headline">Add a Daily/Weekly Target</span>
        </v-card-title>

        <v-card-text>
          <p>
            This will add a daily/weekly target challenge to this event. Each day or week someone reaches the set goal, he or she earns a badge. <!-- There are special badges for every multiple of 5 consecutive days as well (5, 10, 15...). -->
          </p>
          <p v-if="isDays">
            The streak challenge will start on the first day of this event. Multiple activities on the same calendar date are aggregated and can count combined towards the daily goal.
          </p>
          <p v-if="isWeeks">
            Each week will start on the first day of each week (typically Sunday).
          </p>

          <v-text-field
            v-model="request.name" 
            label="Name of the challenge"
            />

          <div class="mt-4">Select the type</div>
          <v-btn-toggle v-model="request.type" mandatory class="multiline-btns">
            <v-btn value="CONSECUTIVE_DAYS" small>
              Days
            </v-btn>
            <v-btn value="CONSECUTIVE_WEEKS" small>
              Weeks
            </v-btn>
          </v-btn-toggle>

          <DistanceTextArea
            v-model="request.target_count" 
            :mode="$helpers.UnitType.NUMBER"
            :label="`Number of ${typeForDisplay}s`" 
            :hint="`Duration of this challenge (in ${typeForDisplay}s)`"
            />

          <DateWithTimeZonePicker v-model="request.start_date" clearable :minDate="event.from" :maxDate="event.till" :timeZone="event.timezone" label="Start date" hint="Leave empty to start on the first day of this event" />

          <div class="mt-4">Select the goal metric</div>
          <v-btn-toggle v-model="metric" mandatory class="multiline-btns">
            <v-btn value="TIME" small>
              Active Time
            </v-btn>
            <v-btn value="STEPS" small>
              Steps
            </v-btn>
          </v-btn-toggle>


          <DistanceTextArea
            v-if="metric == 'STEPS'"
            v-model="request.target_steps" 
            :mode="$helpers.UnitType.NUMBER"
            :label="`Number of steps (per ${typeForDisplay})`" 
            hint="A typical goal is between 5,000 and 10,000 steps per day"
            />

          <DistanceTextArea
            v-if="metric == 'TIME'"
            v-model="request.target_active_time_s" 
            :mode="$helpers.UnitType.NUMBER"
            :multiplier="60"
            :label="`Number of active minutes (per ${typeForDisplay})`" 
            hint="A typical goal is around 30 minutes per day"
            />

          <v-alert outlined v-if="!isValid" type="info" class="mt-4">
            Please complete all fields in this form.
          </v-alert>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="visible=false">Cancel</v-btn>
          <v-btn :disabled="!isValid" color="blue darken-1" outlined @click="visible=false;$emit('submit', request)">Create Challenge</v-btn>
        </v-card-actions>
      </v-card>
  </v-dialog>
</template>

<script>
import DateWithTimeZonePicker from "@/components/DateWithTimeZonePicker";
import DistanceTextArea from "@/components/DistanceTextArea";
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "SelectTrainingPlanDialog",
  components: {
    DateWithTimeZonePicker,
    DistanceTextArea,
  },
  props: {
    event: Object,
  },
  data() {
    return {
      tenant: tenant,
      visible: false,
      metric: null,
      request: { 
        name: null,
        type: 'CONSECUTIVE_DAYS',
        target_count: 0,
        target_active_time_s: null,
        target_steps: null,
      },
    };
  },
  async mounted() {
  },
  methods: {
    async open() {
      this.visible = true;
    },
  },
  computed: {
    isDays() { return this.request.type == 'CONSECUTIVE_DAYS';},
    isWeeks() { return this.request.type == 'CONSECUTIVE_WEEKS';},
    typeForDisplay() { return this.isDays ? 'day' : 'week';},
    isValid() {
      return this.request.name && this.request.type && this.request.target_count > 0 && (this.request.target_active_time_s || this.request.target_steps);
    }
  },
};
</script>
<style lang="scss" scoped>
  
</style>

