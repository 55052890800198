<template>
    <v-container
      fluid
      class="d-flex flex-row align-start pa-0 align-stretch event-container"
    >
        <v-navigation-drawer
            v-if="eventUtil.newLayout && sidebarState !== 'HIDDEN' && !showNotFoundOrNoPermissionsError"
            permanent
            enable-resize-watcher
            :mini-variant="mini"
            mini-variant-width="50"
            class="side-menu"
            width="250px"
            >
            <template v-slot:prepend>
                <v-list-item v-if="$store.state.context === 'microsoft-teams'" dense to="/apps/teams/content" exact color="primary" class="pl-6 py-1">
                    <v-list-item-icon><v-icon>fal fa-chevron-left</v-icon></v-list-item-icon>
                    <v-list-item-title>Microsoft Teams</v-list-item-title>
                </v-list-item>
                <v-list-item v-else dense :to="{ name: 'eventmanager'}" exact color="primary" class="pl-6 py-1">
                    <v-list-item-icon><v-icon>fal fa-chevron-left</v-icon></v-list-item-icon>
                    <v-list-item-title>All Events</v-list-item-title>
                </v-list-item>
            </template>
            <template v-slot:append>
                <v-divider />
                <v-list-item>
                    <v-list-item-title v-if="!mini">&nbsp;</v-list-item-title>
                    <v-btn icon @click.stop.prevent="switchMiniMode">
                        <v-icon v-if="!mini">fa-chevron-left</v-icon>
                        <v-icon v-if="mini">fa-chevron-right</v-icon>
                    </v-btn>
                </v-list-item>
            </template>

            <v-divider />
            <v-list dense rounded>

                <template v-for="(item, i) in menuItems">
                    <v-divider v-if="item.divider" :key="i" class="my-4" />
                    <v-subheader v-else-if="item.header" :key="i" class="mt-4" >{{item.text | uppercase}}</v-subheader>
                    <v-list-item v-else-if="item.leaderboards && !event" :key="i" link color="primary" title="Loading...">
                        <v-list-item-icon><v-icon>fal fa-circle-notch fa-spin</v-icon></v-list-item-icon>
                        <v-list-item-title>Loading event...</v-list-item-title>
                    </v-list-item>
                    <div v-else-if="item.leaderboards && event" :key="i">
                        <v-subheader>LEADERBOARDS</v-subheader>
                        <v-list-group
                            v-for="race in races" :key="race.id"
                            :value="false"
                            :no-action="true"
                            :sub-group="false"
                            :title="race.name"
                            :prepend-icon="'fal ' + $helpers.getScoringIcon(race.scoring)"
                            @click="mini ? $router.push({name: 'eventmanagerRace', params: {id: event.id, raceId: race.id}}) : null"
                            >
                            <template v-slot:activator>
                                <v-list-item-content>
                                <v-list-item-title>{{race.name}}</v-list-item-title>
                                </v-list-item-content>
                            </template>

                            <v-list-item
                                v-for="(item, idx) in getRaceMenu(race)"
                                :key="idx"
                                link
                                :title="item.text"
                                :to="item.to" exact color="primary"
                            >
                                <v-list-item-title>{{ item.text_key ? $t('nav.account.' + item.text_key) : item.text }}</v-list-item-title>
                                <v-list-item-icon v-if="false" small><v-icon>fal {{ item.icon }}</v-icon></v-list-item-icon>
                            </v-list-item>
                        </v-list-group>
                        <v-btn small outlined color="" class="mx-2 mt-2" @click="$refs.raceDialog.createRace()">
                            <v-icon small class="ml-1 mr-6">fa-plus</v-icon> Add
                        </v-btn>
                    </div>
                    <v-list-item v-else :key="i" link :to="item.to && item.to.params.id ? item.to : null" exact color="primary" :title="item.text">
                        <v-list-item-icon><v-icon>fal {{ item.icon }}</v-icon></v-list-item-icon>
                        <v-list-item-title>{{ item.text_key ? $t('nav.account.' + item.text_key) : item.text }}</v-list-item-title>
                    </v-list-item>
                </template>
            </v-list>
        </v-navigation-drawer>

        <div v-if="showNotFoundOrNoPermissionsError" class="content-container mt-16">
          <v-alert type="warning" prominent icon="fa-lock">
            <h3>Event not found or No Permissions to manage</h3>
            <p>Either the event does not exit or you do not have permissions to manage it. Please make sure you are logged in with the correct account.</p>
            <p class="mt-8 mb-0">Id:</p>
            <code>{{$route.params.id}}</code>
            <p class="mt-8">
              <v-btn :to="{ name: 'eventmanager'}">My Events</v-btn>
            </p>
          </v-alert>
        </div>
        <div v-if="!showNotFoundOrNoPermissionsError" class="grey lighten-4 pa-0 content-container">
            <v-toolbar dense v-if="event && eventUtil.newLayout" elevation="0" color="white lighten-4 width-100p" >
            <v-toolbar-title class="ml-4"><router-link class="no-decoration" :to="{name:'eventmanagerView', params: {id: event.id}}">{{event.name}}</router-link></v-toolbar-title>
            <v-spacer></v-spacer>
            <div>
            <v-btn text :href="tenant.eventManagerFaqUrl" target="_blank" class="mr-2">
                <v-icon class="mr-2">fadl fa fa-question-circle</v-icon>
                FAQ
            </v-btn>
            <v-btn v-if="false" text :to="{name:'eventmanagerEdit', params: {id: event.id}}" class="mr-2">
                <v-icon small class="mr-2">fadl fa fa-pencil</v-icon>
                Edit
            </v-btn>
            <v-btn v-if="event && event.published" outlined :to="{name:'event', params: {id: event.id}}" class="mr-2">
                <v-icon small class="mr-2">fadl fa fa-eye</v-icon>
                View
            </v-btn>
            </div>
            </v-toolbar>
            <v-divider v-if="eventUtil.newLayout"/>

            <router-view :class="`${eventUtil.newLayout ? 'container' : ''}`" :tenant="tenant" :event="event"></router-view>
            
            <!-- <div class="container mt-8 text-muted">
                <v-btn v-if="eventUtil.newLayout" x-small text color="grey" @click="enableNewLayout(false)">Back to old layout</v-btn>
                <v-btn v-if="!eventUtil.newLayout" x-small text color="grey" @click="enableNewLayout(true)">Try the new layout!</v-btn>
            </div> -->

            <RaceDialog ref="raceDialog" :event="event" @save="saveNewRace"/>
        </div>
    </v-container>
</template>


<script>
import { mapGetters } from "vuex";
import { EventBus } from '@/plugins/eventbus.js';
import eventManagerService from "@/services/eventManagerService";
import siteData from '@/data/site.json'
import EventUtil from "@/util/eventUtil";
import Header from './_Header.vue'
import RaceDialog from './_RaceDialog.vue'
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "EventLayoutBase",
  components: {
    Header,
    RaceDialog,
  },
  props: {
  },
  data() {
    return {
      tenant: tenant,
      event: null,
      mini: false,
      siteData: siteData,
      showNotFoundOrNoPermissionsError: false,
    };
  },
  async mounted() {
    this.mini = this.$cookies.isKey('em.menu.mini');
    this.loadData();
    
    EventBus.$on('login-state-change', async user => {
      this.loadData();
    });
    
    EventBus.$on('eventmanager-request-save', async () => {
      const saved = await this.eventUtil.save();
      if (saved) {
        this.event = this.eventUtil.event; // force refresh down the tree
      }
    });
    EventBus.$on('eventmanager-refresh', async state => {
        console.log('refresh requested', state);
        this.event = state.event; // force refresh down the tree
    });
  },
  methods: {
    async loadData() {
      if (this.user) {
        await this.getEvent(this.$route.params.id);
      }
    },
    async getEvent(id) {
      try {
        this.event = (await eventManagerService.get(id)).data;
        this.showNotFoundOrNoPermissionsError = false;
      }
      catch {
        this.showNotFoundOrNoPermissionsError = true;
      }
    },
    async saveNewRace(race) {
      await this.eventUtil.save();
      this.event = this.eventUtil.event;
      // the race doesn't have an id but will have gotten one on save, so try find it by name
      const newlySavedRace = this.event.races.find(x => x.name == race.name);
      if (newlySavedRace) {
        this.$router.push({name: 'eventmanagerRace', params: { id: this.event.id, raceId: newlySavedRace.id }});
      }
    },
    getRaceMenu(race) {
      var params = {id: this.event.id, raceId: race.id };
      return [
        { text: 'View', icon: 'fa-info', to: { name: 'eventmanagerRace', params: params } },
        race.type === 'EXTERNAL' ? { text: 'Splits', icon: 'fa-bars', to: { name: 'eventmanagerRaceSplits', params: params } } : { text: 'Badges', icon: 'fa-shield', to: { name: 'eventmanagerRaceBadges', params: params } },
        //{ text: 'Leaderboard', icon: 'fa-line-columns', to: { name: 'eventmanagerRaceResultColumns', params: params } },
        { text: 'Manage Results', icon: 'fa-poll-people', to: { name: 'eventmanagerRaceResults', params: params } },
      ].filter(x => x!=null);
    },
    switchMiniMode() {
        this.mini = !this.mini;
        if (this.mini) {
            this.$cookies.set('em.menu.mini', true);
        }
        else {
            this.$cookies.remove('em.menu.mini');
        }
    },
    enableNewLayout(state) {
        if (state) {
            this.$cookies.set('newlayout', true);
        }
        else {
            this.$cookies.set('newlayout', false);
        }
        window.location.reload();
    },
  },
  computed: {
    races() {
        return this.event && this.event.races ? this.event.races : [];
    },
    eventUtil() {
      return new EventUtil(this, this.event, this.race);
    },
    sidebarState() {
      return this.$route.meta && this.$route.meta.sidebar;
    },
    breadcrumbItems() {
      return !this.event ? [] : [
        { text: 'Event Manager', exact: true, to: { name: 'eventmanager' } },
        { text: this.event.name, exact: true, to: { name: 'eventmanagerView', params: {id: this.event.id}} },
      ];
    },
    menuItems() {
      var params = !this.event ? {id: null } : {id: this.event.id };
      return [
          { text: 'Overview', icon: 'fa-home', to: { name: 'eventmanagerView', params: params } },
          { leaderboards: true },
          { header: true, text: 'Users & Teams' },
          { text: 'Users', icon: 'fa-user', to: { name: 'eventmanagerParticipants', params: params } },
          { text: 'Teams', icon: 'fa-users', to: { name: 'eventmanagerTeams', params: params } },
          { text: 'Registrations', icon: 'fa-table', to: { name: 'eventmanagerRegistrations', params: params } },
          { header: true, text: 'Manage' },
          { text: 'Emails', icon: 'fa-envelope', to: { name: 'eventmanagerEmails', params: params } },
          { text: 'Feed', icon: 'fa-bullhorn', to: { name: 'eventmanagerFeed', params: params } },
          { text: 'Reports', icon: 'fa-file-spreadsheet', to: { name: 'eventmanagerReports', params: params } },
          { text: 'Photos & Selfies', icon: 'fa-images', to: { name: 'eventmanagerGallery', params: params } },
          { text: 'Bibs & Certificates', icon: 'fa-file-certificate', to: { name: 'eventmanagerCertificates', params: params } },
          { header: true, text: 'Advanced' },
           (this.event && this.event.type === 'RESULTS') ? null : { text: 'Activity Conversions', icon: 'fa-pencil-ruler', to: { name: 'eventmanagerActivityConversions', params: params } },
          { text: 'Integrations', icon: 'fa-puzzle-piece', to: { name: 'eventmanagerIntegrations', params: params } },
          { text: 'Permissions', icon: 'fa-user-unlock', to: { name: 'eventmanagerPermissions', params: params } },
      ].filter(x => x!=null);
    },
    ...mapGetters({
      user: "user"
    })
  },
};
</script>

<style lang="scss">
    .width-100p { width: inherit !important;}
    .event-container {
      width: inherit !important;

      .side-menu {
        min-width: 200px;
        .v-list-item__icon { max-width: 24px !important; min-width: 24px !important; margin-left: 0 !important;}
        .v-list-item__action:first-child, .v-list-item__icon:first-child {margin-right: 24px;}
        .v-list-item {padding-right: 0px;}
      }
      .content-container {
        flex-grow: 1;
        min-width: 400px;
      }
    }
    .info-container {
        .label { font-size: 80%; opacity: .8; }
        .value { margin-bottom: 10px; }
        .value pre { white-space: pre-wrap;}
        /*.value:last-child { margin-bottom: 0px; ;}*/
    }

</style>

