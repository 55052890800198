<template>
  <v-menu
    ref="till"
    v-model="showPicker"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        v-model="formatted"
        :label="label"
        :hint="formattedHint"
        :disabled="disabled"
        persistent-hint
        prepend-icon="fa-calendar"
        readonly
        class="pt-0"
        :clearable="clearable"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker 
      no-title 
      v-bind:value="valueAsIsoDate"
      @input="handleChange"
      :show-current="false"
      :min="minDate"
      :max="maxDate"
      :disabled="disabled"
      > 
    </v-date-picker>
  </v-menu>
</template>



<script>
import { DateTime } from 'luxon'

export default {
  name: "DateWithTimeZonePicker",
  components: {
  },
  props: {
    value: String,
    label: String,
    hint: String,
    timeZone: String,
    clearable: Boolean,
    minDate: String,
    maxDate: String,
    disabled: Boolean,
  },
  data() {
    return {
      showPicker: false,
    };
  },
  async mounted() {
  },
  methods: {
    handleChange(e) {
      var date = DateTime.fromISO(e, { zone: this.timeZone }).startOf('day').toISODate();
      console.log('Date picker emit value', e, 'parsed to time zone:', date);
      this.$emit('input', date); 
      this.showPicker = false;
    },
  },
  computed: {
    valueAsIsoDate: {
      get() {
        //console.log('DATE', this.value == null ? null : DateTime.fromISO(this.value).toISODate())
        return this.value == null ? null : DateTime.fromISO(this.value, { zone: this.timeZone }).toISODate();
      },
      set(value) {
        // handled by handleChange
      }
    },
    formatted: {
      get() {
        return this.value == null ? null : DateTime.fromISO(this.value, { zone: this.timeZone }).toLocaleString(DateTime.DATE_SHORT);
      },
      set (value) {
        //this.value = value;
        var date = DateTime.fromISO(value, { zone: this.timeZone }).startOf('day').toISODate();
        console.log('Date picker emit value', value, 'parsed to time zone:', date);
        this.$emit('input', date); 
      }
    },
    formattedHint() {
      if (this.hint) {
        return this.hint;
      }
      return this.value == null || this.timeZone == null ? null : DateTime.fromISO(this.value, { zone: this.timeZone }).toLocaleString(DateTime.DATE_FULL);
    },
  },
};
</script>
<style lang="scss">
</style>

