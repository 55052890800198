<template>
  <v-dialog v-if="event" v-model="visible" max-width="650">
    <v-card>
        <v-card-title>
            <span class="headline">Add a Last One Standing challenge</span>
        </v-card-title>

        <v-card-text>
          <p>
            A Last One Standing challenge is a series of rounds where each participant must complete the given distance in each round to be able to start in the next round.
          </p>

          <v-text-field
            v-model="request.name" 
            label="Name of the challenge"
            />

          <v-select
            :items="siteData.activities.filter(x => !x.hidden)"
            v-model="request.activity_type" 
            item-value="type"
            item-text="text"
            label="Allowed Sport"
            />
          
          <DateAndTimeWithTimeZonePicker v-model="request.start_time" ref="timePicker" :timeZone="event.timeZoneOlson" label="Date of challenge" timeLabel="Start time of 1st round" />

          <v-row>
            <v-col cols="6">
              <DistanceTextArea
                v-model="request.number_slots" 
                :mode="$helpers.UnitType.NUMBER"
                :label="`Number of rounds`" 
                :hint="`The maximum number of rounds to be completed.`"
                />
            </v-col>
            <v-col cols="6">
              <DistanceTextArea
                v-model="request.slot_distance" 
                :mode="$helpers.UnitType.DISTANCE"
                :label="`Minimum distance per round (in ${event.unit == 'METRIC' ? 'km' : 'mi'})`" 
                />

            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6">
              <DistanceTextArea
                v-model="request.slot_duration" 
                :mode="$helpers.UnitType.NUMBER"
                :label="`Duration of each round (in minutes)`" 
                hint=""
                />
            </v-col>
            <v-col cols="6">
              <DistanceTextArea
                v-model="request.slot_overlap" 
                :mode="$helpers.UnitType.NUMBER"
                :label="`Overlap before and after each round (in minutes)`" 
                hint="Give 1 or 2 minutes to prevent DSQs because someone was a few seconds early or late."
                />
            </v-col>
          </v-row>



          <v-alert v-if="endTime" outlined type="info" class="mt-4">
            This challenge ends on <strong>{{ endTime | localDate('ddd L LTS Z') }}</strong>.
          </v-alert>

          <v-alert v-if="!isValid" outlined type="warning" class="mt-4">
            Please complete all fields in this form.
          </v-alert>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="visible=false">Cancel</v-btn>
          <v-btn :disabled="!isValid" color="blue darken-1" outlined @click="visible=false;$emit('submit', request)">Create Challenge</v-btn>
        </v-card-actions>
      </v-card>
  </v-dialog>
</template>

<script>
import DistanceTextArea from "@/components/DistanceTextArea";
import DateAndTimeWithTimeZonePicker from "@/components/DateAndTimeWithTimeZonePicker";
import { DateTime, Duration } from 'luxon';
import siteData from '@/data/site.json'
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "GenerateLastManStandingDialog",
  components: {
      DistanceTextArea,
      DateAndTimeWithTimeZonePicker,
  },
  props: {
    event: Object,
  },
  data() {
    return {
      siteData: siteData,
      tenant: tenant,
      visible: false,
      metric: null,
      request: { 
        name: null,
        activity_type: null,
        start_time: null,
        number_slots: 5,
        slot_duration: 60,
        slot_overlap: 1,
        slot_distance: 5000,
      },
    };
  },
  async mounted() {
  },
  methods: {
    async open() {
      this.visible = true;
    },
  },
  computed: {
    endTime() {
      if (this.event && this.request.start_time && this.request.number_slots && this.request.slot_duration) {
        var start = DateTime.fromISO(this.request.start_time, { zone: this.event.timeZoneOlson });
        var duration = Duration.fromObject({ minutes: this.request.number_slots * this.request.slot_duration });
        return start.plus(duration);
      }
      return null;
    },
    isValid() {
      return this.request.name && this.request.start_time && this.request.number_slots > 0 && this.request.slot_duration > 0 && this.request.slot_distance > 0;
    }
  },
};
</script>
<style lang="scss" scoped>
  
</style>

